<template>
    <transition leave-active-class="animate__animated animate__bounceOutLeft" >
        <router-view />
        </transition>
</template>


<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
    .slide-enter-active,
    .slide-leave-active {
        transition: all 0.25s ease;
    }


    .slide-enter-to {
        position: absolute;
        right: 0;
    }

    .slide-enter-from {
        position: absolute;
        right: -100%;
    }


    .slide-leave-to {
        position: absolute;
        left: -100%;
    }


    .slide-leave-from {
        position: absolute;
        left: 0;
    }
</style>